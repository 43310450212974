import React, { useState, useEffect, useMemo } from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import SvgIcon from "@material-ui/core/SvgIcon";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  MuiThemeProvider,
  createTheme,
  makeStyles,
  withStyles,
} from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import IndeterminateCheckBoxOutlinedIcon from "@material-ui/icons/IndeterminateCheckBoxOutlined";
import CancelPresentationOutlinedIcon from "@material-ui/icons/CancelPresentationOutlined";
import CustomButton from "../../UI/button/button";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import FrameworksDialog from "./frameworksDialog";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import reportDataLeftStyles from "./reportDataLeftStyles";
import LinearProgress from "@material-ui/core/LinearProgress";
import {
  BusinessWhite,
  EnvironmentalWhite,
  GeneralDisclosureWhite,
  GovernanceWhite,
  HumanWhite,
  SocialWhite,
  Environmental,
  Social,
  Governance,
  Human,
  GeneralDisclosure,
  Business,
} from "../../../utils/MaterialityAssessmentUtils/materialityAssessmentUtils";
import { mainTopicArray } from "../../../constants/materialityConst/materialityConst";
import clsx from "clsx";
import CustomTextfield from "../../UI/textfield/textfield";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  IconButton,
  MenuItem,
  Select,
} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import AuditTab from "./leftSubComponents/AuditTab";

import { useDispatch } from "react-redux";
import { setFrameworkPreference } from "../../../actions/basicinfoAction/basicinfoAction";
import NoData from "../NoData/NoData";

const theme = createTheme({
  palette: {
    secondary: {
      main: "#47BB76",
    },
  },

  metricIcon: {
    visibility: "hidden",
  },
  CustomTextfield: {
    margin: "0px 0px 5px 25px",
    height: "22px",
    padding: "5px",
  },
});

export const TopicCard = ({ topicMetric, children }) => {
  const classes = reportDataLeftStyles();

  const { topic_name } = topicMetric;
  return (
    <>
      <Card variant="elevation" elevation={3} className={classes.Card}>
        <CardContent className={classes.CardContent}>
          <div className={classes.mainTopicContainer}>
            {topic_name === "Social Capital" && (
              <Social className={classes.size} />
            )}
            {(topic_name === "Environment" ||
              topic_name === "Environmental") && (
              <Environmental className={classes.size} />
            )}
            {topic_name === "Leadership & Governance" && (
              <Governance className={classes.size} />
            )}
            {topic_name === "Human Capital" && (
              <Human className={classes.size} />
            )}
            {topic_name === "Business Model & Innovation" && (
              <Business className={classes.size} />
            )}
            {topic_name === "General Disclosure" && (
              <GeneralDisclosure className={classes.size} />
            )}
            {(topic_name === "SECTION A" ||
              topic_name === "SECTION B" ||
              topic_name === "SECTION C") && (
              <GeneralDisclosure className={classes.size} />
            )}
            {/* {topic_name === 'SECTION B' && (
                <GeneralDisclosure className={classes.size} />
              )}
              {topic_name === 'SECTION C' && (
                <GeneralDisclosure className={classes.size} />
              )} */}
            <Typography className={classes.mainTopicText}>
              {topic_name}
            </Typography>
          </div>
          {topic_name === "Social Capital" && (
            <div className={classes.socialBorder}></div>
          )}
          {(topic_name === "Environment" || topic_name === "Environmental") && (
            <div className={classes.environmentBorder}></div>
          )}
          {topic_name === "Leadership & Governance" && (
            <div className={classes.governanceBorder}></div>
          )}
          {topic_name === "Human Capital" && (
            <div className={classes.humanBorder}></div>
          )}
          {topic_name === "Business Model & Innovation" && (
            <div className={classes.businessBorder}></div>
          )}
          {topic_name === "General Disclosure" && (
            <div className={classes.generalDisclosureBorder}></div>
          )}
          {(topic_name === "SECTION A" ||
            topic_name === "SECTION B" ||
            topic_name === "SECTION C") && (
            <div className={classes.generalDisclosureBorder}></div>
          )}
          {/* {topic_name === 'SECTION B' && (
              <div className={classes.generalDisclosureBorder}></div>
            )}
            {topic_name === 'SECTION C' && (
              <div className={classes.generalDisclosureBorder}></div>
            )} */}
          {children}
        </CardContent>
      </Card>
    </>
  );
};

const ReportDataLeft = (props) => {
  const classes = reportDataLeftStyles();

  const {
    assessment_id,
    login,
    basicInfo,
    fieldData,
    getMetrics,
    getCollectMetrics,
    setReportTab,
    getReviewData,
    setCurrentMetric,
    setFrameworkId,
    setFrameworkDefaultFilter,
    updateFramework,
    setCurrentReviewMetrics,
    setMetrics,
    setReviewMetrics,
    freezeData,
    getAllMetrics,
    getTopicMetric,
    setTopicMetric,
    getBrsrMetric,
    resetTemplateType,
  } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    if (fieldData.reportTab === 1) {
      getReviewData(login.token, assessment_id);
    }
  }, [fieldData.reportTab]); // eslint-disable-line react-hooks/exhaustive-deps

  const [expandedIds, setExpandedIds] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);

  const [hover, setHover] = useState(false);
  const [frameworkOpen, setFrameworkOpen] = useState(false);
  const [itemId, setItemId] = useState("");
  const [mainTopicId, setMainTopicId] = useState("");
  useEffect(() => {
    if (fieldData.reportTab === 0) {
      // console.log(itemId)
      itemId &&
        getCollectMetrics(
          login.token,
          assessment_id,
          itemId,
          mainTopicId,
          basicInfo.templateType,
          localStorage.getItem("current_organisation")
        );
    }
  }, [fieldData.reportTab]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTabChange = (event, newValue) => {
    // console.log(newValue,event.target.value)
    setReportTab(newValue);
  };

  function TabPanel(props) {
    const { children, value, index } = props;
    return <div>{value === index && <Box>{children}</Box>}</div>;
  }

  const MetricCard = ({ mainTopic, masterTopics }) => {
    // console.log("masterTopics", masterTopics);
    const mainTopicArray = [
      "Social Capital",
      "Human Capital",
      "Environmental",
      "Environment",
      "Business Model & Innovation",
      "Leadership & Governance",
      "General Disclosure",
      "SECTION A",
      "SECTION B",
      "SECTION C",
    ];

    // const MetricMainCard = ({}) => {
    //   return (
    //     <Accordion>
    //       <AccordionSummary>getails</AccordionSummary>
    //       <AccordionDetails>
    //         <Paper
    //           className={clsx(classes.metricCardHeader, classes.BoxBaground)}
    //         >
    //           <Social className={classes.fontSize} />
    //           <Typography className={classes.Typography}>|</Typography>
    //           <Typography className={classes.Typography}>Section A</Typography>
    //         </Paper>
    //       </AccordionDetails>
    //     </Accordion>
    //   );
    // };

    return (
      <>
        <Box className={classes.Box}>
          {/* <MetricMainCard /> */}
          {mainTopic.main_topic_name === "Social Capital" && (
            <Box
              className={clsx(classes.metricCardHeader, classes.BoxBaground)}
            >
              <Social className={classes.fontSize} />
              <Typography className={classes.Typography}>
                {mainTopic.topic_name}
              </Typography>
            </Box>
          )}
          {mainTopic.main_topic_name === "Human Capital" && (
            <Box
              className={clsx(classes.metricCardHeader, classes.BoxBaground1)}
            >
              <Human className={classes.fontSize} />
              <Typography className={classes.Typography}>
                {mainTopic.topic_name}
              </Typography>
            </Box>
          )}
          {(mainTopic.main_topic_name === "Environmental" ||
            mainTopic.main_topic_name === "Environment") && (
            <Box
              className={clsx(classes.metricCardHeader, classes.BoxBaground2)}
            >
              <Environmental className={classes.fontSize} />
              <Typography className={classes.Typography}>
                {mainTopic.topic_name}
              </Typography>
            </Box>
          )}
          {mainTopic.main_topic_name === "Business Model & Innovation" && (
            <Box
              className={clsx(classes.metricCardHeader, classes.BoxBaground3)}
            >
              <Business className={classes.fontSize} />
              <Typography className={classes.Typography}>
                {mainTopic.topic_name}
              </Typography>
            </Box>
          )}
          {mainTopic.main_topic_name === "Leadership & Governance" && (
            <Box
              className={clsx(classes.metricCardHeader, classes.BoxBaground4)}
            >
              <Governance className={classes.fontSize} />
              <Typography className={classes.Typography}>
                {mainTopic.topic_name}
              </Typography>
            </Box>
          )}
          {mainTopic.main_topic_name === "General Disclosure" && (
            <Box
              className={clsx(classes.metricCardHeader, classes.BoxBaground5)}
            >
              <GeneralDisclosureWhite className={classes.fontSize} />
              <Typography className={classes.Typography}>
                {mainTopic.topic_name}
              </Typography>
            </Box>
          )}
          {(mainTopic.main_topic_name === "SECTION A" ||
            mainTopic.main_topic_name === "SECTION B" ||
            mainTopic.main_topic_name === "SECTION C") && (
            <Box
              className={clsx(classes.metricCardHeader, classes.BoxBaground5)}
            >
              <Grid container alignItems="center">
                <GeneralDisclosureWhite className={classes.fontSize} />
                <Typography className={classes.Typography}>
                  {mainTopic.main_topic_name}
                  {/* {mainTopic.topic_name} */}
                </Typography>
              </Grid>
              <Grid>
                <Typography
                  variant="caption"
                  style={{
                    fontSize: "13px",
                    color: "grey",
                    paddingLeft: "10px",
                  }}
                >
                  Principle wise Perfomance disclosures
                </Typography>
              </Grid>
            </Box>
          )}
          {!mainTopicArray.includes(mainTopic.main_topic_name) && (
            <Box
              className={clsx(classes.metricCardHeader, classes.BoxBaground4)}
            >
              <Typography className={classes.Typography}>
                {mainTopic.main_topic_name}
              </Typography>
            </Box>
          )}

          <Grid container>
            {mainTopic.sub_metrics.length !== 0 &&
              mainTopic.sub_metrics.map((metric, index) => (
                <Grid
                  key={index}
                  className={`
                    ${
                      fieldData.currentReviewMetric.metric_id ===
                      metric.metric_id
                        ? classes.selectedMetricCardBar
                        : classes.metricCardBar
                    }
                      ${
                        mainTopic.main_topic_name === "Social Capital" &&
                        classes.metricCardBarBorderSocial
                      }
                      ${
                        mainTopic.main_topic_name === "Human Capital" &&
                        classes.metricCardBarBorderHuman
                      }
                      ${
                        (mainTopic.main_topic_name === "Environmental" ||
                          mainTopic.main_topic_name === "Environment") &&
                        classes.metricCardBarBorderEnvironmental
                      }
                      ${
                        mainTopic.main_topic_name ===
                          "Business Model & Innovation" &&
                        classes.metricCardBarBorderBusiness
                      }
                      ${
                        mainTopic.main_topic_name ===
                          "Leadership & Governance" &&
                        classes.metricCardBarBorderGovernance
                      }
                      ${
                        mainTopic.main_topic_name === "General Disclosure" &&
                        classes.metricCardBarBorderGeneralDisclosure
                      }
                      `}
                >
                  <Grid
                    item
                    container
                    alignItems="center"
                    onClick={() => {
                      // getMetrics(
                      //   login.token,
                      //   assessment_id,
                      //   metric.metric_id,
                      //   true,
                      //   basicInfo.templateType
                      // );
                      // setCurrentReviewMetrics(metric);
                    }}
                  >
                    {/* <Grid>
                          {`disclosure ${metric.metric_name.split(':')[0]}`}
                        </Grid> */}
                    <Grid item xs={3}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "72%",
                          height: "30px",
                          padding: "5px",
                          border: "none",
                          backgroundColor: "#EBF1F8",
                          color: "#3374B9",
                          borderRadius: "5px",
                        }}
                      >
                        <div
                          style={{
                            border: "1px solid #3374B9",
                            backgroundColor: "#3374B9",
                            margin: "2px",
                            borderRadius: "5px",
                            width: "3px",
                            height: "18px",
                            fontWeight: "bold",
                          }}
                        ></div>
                        <div>
                          <Typography style={{ padding: "2px 5px 2px 2px" }}>
                            {`Principle ${metric.metric_name.split(":")[0]}`}
                          </Typography>
                        </div>
                      </div>
                    </Grid>

                    <Grid
                      container
                      style={{ alignContent: "center" }}
                      item
                      xs={5}
                    >
                      <Grid md={1} item>
                        {" "}
                        <Typography
                          style={{ fontSize: "13px", fontWeight: "500" }}
                        >
                          28%
                        </Typography>
                      </Grid>
                      <Grid md={11} style={{ padding: "8px" }} item>
                        <LinearProgress
                          variant="determinate"
                          style={{
                            color: "#3374B9",
                            height: "5px",
                            borderRadius: "8px",
                          }}
                          value={28}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <ArrowForwardIcon className={classes.metricIcon} />
                    </Grid>
                  </Grid>

                  <Grid>
                    <Typography className={classes.Typography1}>
                      {metric.metric_name.split(":")[1]}
                    </Typography>
                    <Typography style={{ fontSize: "1em", color: "grey" }}>
                      {" "}
                      Business should respect and promote the well-being of
                      employees including those in their value chains
                    </Typography>
                  </Grid>
                </Grid>
              ))}
          </Grid>
        </Box>
      </>
    );
  };

  const handleToggle = (event, nodeIds) => {
    setExpandedIds(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    setSelectedIds(nodeIds);
  };

  const StyledTreeItem = withStyles({
    root: {
      "&:focus > $content $label": {
        backgroundColor: "#D6E3F173",
      },
      "&$selected > $content $label": {
        backgroundColor: "#D6E3F173",
      },
      "&$selected > $content $label:hover, &$selected:focus > $content $label":
        {
          backgroundColor: "#D6E3F173",
        },
      paddingLeft: 5,
    },
    label: {
      width: "100%",
      padding: 15,
      position: "relative",
      fontFamily: "poppins",
      fontSize: 16,
      lineHeight: "24px",
      fontWeight: "600",
      "&:hover": {
        backgroundColor: "#D6E3F173",
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: "transparent",
        },
      },
    },
  })(TreeItem);

  const GetTreeItems = (items, mainTopicId) => {
    // console.log('GetTreeItems',items)
    return items.map((item, i) => {
      let sub_topics = undefined;
      let sub_metrics = undefined;
      if (item.sub_topics && item.sub_topics.length > 0) {
        sub_topics = GetTreeItems(item.sub_topics, mainTopicId);
      } else if (item.sub_metrics && item.sub_metrics.length > 0) {
        sub_metrics = GetTreeItems(item.sub_metrics, mainTopicId);
      }
      return item.sub_topics && item.sub_topics.length > 0 ? (
        <StyledTreeItem
          key={item._id}
          nodeId={item._id}
          label={item.topic_name + " " + item.description}
          children={sub_topics}
        />
      ) : item.sub_topics ? (
        <StyledTreeItem
          key={item._id}
          nodeId={item._id}
          label={item.topic_name + " " + item.description}
          children={sub_metrics}
        />
      ) : (
        item.sub_metrics &&
        item.sub_metrics.length > 0 && (
          <div key={item._id} className={classes.div}>
            <StyledTreeItem
              key={item._id}
              nodeId={item._id}
              label={item.metric_name + " " + item.metric_desc}
              onClick={() => {
                setItemId(item._id);
                setMainTopicId(item.main_topic_ids);
                getCollectMetrics(
                  login.token,
                  assessment_id,
                  item._id,
                  item.main_topic_ids,
                  basicInfo.templateType,
                  localStorage.getItem("current_organisation")
                );
                setCurrentMetric(item);
                setHover(true);
              }}
            />

            {/* <ArrowForwardIcon
              className={
                hover && item._id === fieldData.currentMetric._id
                  ? classes.arrowVisible
                  : classes.arrowHidden
              }
            /> */}
          </div>
        )
      );
    });
  };

  const DataTreeView = ({ items, mainTopicId }) => {
    return (
      <TreeView
        expanded={expandedIds}
        selected={selectedIds}
        onNodeToggle={handleToggle}
        onNodeSelect={handleSelect}
        defaultCollapseIcon={<IndeterminateCheckBoxOutlinedIcon />}
        defaultExpandIcon={<AddBoxOutlinedIcon />}
        defaultEndIcon={<CancelPresentationOutlinedIcon />}
        style={{ paddingLeft: 15 }}
      >
        {GetTreeItems(items, mainTopicId)}
      </TreeView>
    );
  };

  const [metricList, setMetricLis] = useState({ suggestion: "" });
  const [obj, setobj] = useState({
    frameworkId: basicInfo.frameworkId,
  });

  useEffect(() => {
    setReportTab(0);

    const framework_exists = basicInfo.frameworks?.some(
      (e) => e?._id === basicInfo.frameworkId
    );

    if (basicInfo?.framework_preference && framework_exists) {
      basicInfo?.framework_preference?.toLowerCase() === "recommended"
        ? setMetricLis({ ...metricList, suggestion: "Recommended" })
        : setMetricLis({ ...metricList, suggestion: "All Disclosures" });
    } else {
      basicInfo.default_filter === "recommended"
        ? setMetricLis({ ...metricList, suggestion: "Recommended" })
        : setMetricLis({ ...metricList, suggestion: "All Disclosures" });
    }
  }, []);

  useEffect(() => {
    const framework_exists = basicInfo.frameworks?.some(
      (e) => e?._id === basicInfo.frameworkId
    );

    if (basicInfo?.framework_preference && framework_exists) {
      basicInfo?.framework_preference.toLowerCase() === "recommended"
        ? setMetricLis({ ...metricList, suggestion: "Recommended" })
        : setMetricLis({ ...metricList, suggestion: "All Disclosures" });
    } else {
      if (basicInfo.default_filter === "recommended") {
        setMetricLis({ ...metricList, suggestion: "Recommended" });
      } else if (basicInfo.default_filter === "all_disclosures") {
        setMetricLis({ ...metricList, suggestion: "All Disclosures" });
      }
    }
  }, [basicInfo.frameworkId, basicInfo.templateType]);

  const handleFramworkClose = (id, template, defaultFilter) => {
    if (id !== basicInfo.frameworkId || template !== basicInfo.templateType) {
      setTopicMetric([]);
      setFrameworkOpen(false);
      updateFramework(
        login.token,
        assessment_id,
        id,
        template === "" ? basicInfo.templateType : template
      );
      setFrameworkId(id);
      setobj({ ...obj, frameworkId: id });
      setFrameworkDefaultFilter(defaultFilter);
      setMetrics([]);
      setCurrentMetric([]);
    } else {
      setFrameworkOpen(false);
    }
  };

  useEffect(() => {
    return () => {
      setCurrentMetric([]);
      // setCurrentReviewMetrics({});
      setReviewMetrics([]);
      setMetrics([]);
      setTopicMetric([]);
      setMetrics([]);
    };
  }, [assessment_id, basicInfo.frameworkId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // console.log('basicInfo', basicInfo)
    if (basicInfo.default_filter === "recommended") {
      if (metricList.suggestion === "All Disclosures") {
        getAllMetrics(
          login.token,
          basicInfo.frameworkId,
          basicInfo.templateType,
          assessment_id
        );
        setMetrics([]);
        setTopicMetric([]);
        setCurrentMetric({});
        setExpandedIds([]);
      } else if (metricList.suggestion === "Recommended") {
        getTopicMetric(
          login.token,
          assessment_id,
          basicInfo.frameworkId,
          basicInfo.templateType
        );
        setMetrics([]);
        setTopicMetric([]);
        setCurrentMetric({});
        setExpandedIds([]);
      }
    } else if (basicInfo.default_filter === "all_disclosures") {
      if (metricList.suggestion === "All Disclosures") {
        getAllMetrics(
          login.token,
          basicInfo.frameworkId,
          basicInfo.templateType,
          assessment_id
        );
        setMetrics([]);
        setTopicMetric([]);
        setCurrentMetric({});
        setExpandedIds([]);
      } else if (metricList.suggestion === "Recommended") {
        getTopicMetric(
          login.token,
          assessment_id,
          basicInfo.frameworkId,
          basicInfo.templateType
        );
        setMetrics([]);
        setTopicMetric([]);
        setCurrentMetric({});
        setExpandedIds([]);
      }
    }
  }, [metricList]); // eslint-disable-line react-hooks/exhaustive-deps

  // console.log('metricList',metricList)
  const handleFilterChange = (value) => {
    setMetricLis({ ...metricList, suggestion: value });
    dispatch(setFrameworkPreference(assessment_id, value));
  };

  const FrameworksDialogs = React.useMemo(
    () => (
      <FrameworksDialog
        setMetricLis={setMetricLis}
        open={frameworkOpen}
        setOpen={setFrameworkOpen}
        framework={basicInfo.filteredFrameworks}
        template={basicInfo.templateType}
        onClose={handleFramworkClose}
      />
    ),
    [frameworkOpen] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const [searchText, setSearchText] = useState({
    search: "",
    filterData: [],
  });
  const searchDeep = (data, searchText) => {
    const matches = data.filter((metric) => {
      return metricSearch(metric, searchText.toLowerCase());
    });

    if (matches.length > 0) return matches;

    for (let i = 0; i < data.length; i++) {
      if (data[i].children) {
        let found = searchDeep(data[i].children);
        if (found.length > 0) return found;
      }
    }
    return [];
  };

  const metricSearch = (metric, searchText) => {
    if (metric.topic_name.toLowerCase().includes(searchText.trim())) {
      return metric;
    } else if (metric.sub_topics.length > 0) {
      let subm = metric.sub_topics.filter((sm) => {
        if (sm.topic_name.toLowerCase().includes(searchText.trim())) {
          return sm;
        } else {
          if (sm.sub_topics && sm.sub_topics.length > 0) {
            let thirdsubm = sm.sub_topics.filter((thirdsm) => {
              if (
                thirdsm.topic_name.toLowerCase().includes(searchText.trim())
              ) {
                return thirdsm;
              }
            });
            if (thirdsubm.length > 0) return metric;
          } else if (sm.sub_metrics && sm.sub_metrics.length > 0) {
            let thirdsubm = sm.sub_metrics.filter((thirdsm) => {
              if (
                thirdsm.metric_name.toLowerCase().includes(searchText.trim())
              ) {
                return thirdsm;
              }
            });
            if (thirdsubm.length > 0) return metric;
          }
        }
      });
      if (subm.length > 0) return metric;
    } else if (metric.sub_metrics.length > 0) {
      let subm = metric.sub_metrics.filter((sm) => {
        if (sm.metric_name.toLowerCase().includes(searchText.trim())) {
          return sm;
        } else {
          if (sm.sub_topics && sm.sub_topics.length > 0) {
            let thirdsubm = sm.sub_topics.filter((thirdsm) => {
              if (
                thirdsm.topic_name.toLowerCase().includes(searchText.trim())
              ) {
                return thirdsm;
              }
            });
            if (thirdsubm.length > 0) return metric;
          } else if (sm.sub_metrics && sm.sub_metrics.length > 0) {
            let thirdsubm = sm.sub_metrics.filter((thirdsm) => {
              if (
                thirdsm.metric_name.toLowerCase().includes(searchText.trim())
              ) {
                return thirdsm;
              }
            });
            if (thirdsubm.length > 0) return metric;
          }
        }
      });
      // console.log('subm',subm)
      if (subm.length > 0) return metric;
    }

    // let filtered = fieldData.topicMetric
    //   .map((item) => item)
    //   .filter((metric) => {
    //     // if (metric.sub_metrics.length === 0 || metric.sub_topics.length === 0) {
    //     if (fieldData.topicMetric.length > 0) {
    //       let subm = fieldData.topicMetric.filter((m) => {
    //         if (m.topic_name.toLowerCase().includes(e.target.value.trim())) {
    //           console.log('metric if', metric);
    //           return m;
    //         }
    //       });
    //       if (subm.length > 0) return metric;
    //     } else if (metric.sub_metrics.length === 0) {
    //       if (metric.sub_topics.length > 0) {
    //         let subm = metric.sub_topics.filter((sm) => {
    //           if (sm.topic_name.toLowerCase().includes(e.target.value.trim())) {
    //             console.log('metric if', metric);
    //             return sm;
    //           }
    //         });
    //         if (subm.length > 0) return metric;
    //       }
    //     } else if (metric.sub_topics.length === 0) {
    //       if (metric.sub_metrics.length > 0) {
    //         let subm = metric.sub_metrics.filter((sm) => {
    //           if (
    //             sm.metric_name.toLowerCase().includes(e.target.value.trim())
    //           ) {
    //             console.log('metric else', sm);
    //             return sm;
    //           }
    //         });
    //         if (subm.length > 0) return metric;
    //       }
    //     }

    //     // } else{
    //     //   console.log('metric 2',metric)
    //     // }
    //   });
    // console.log('fil', filtered);
    // e.target.value === ''
    // ? setReviewMetricFiltered([])
    // : setReviewMetricFiltered(filtered);
  };

  return (
    <Card
      className={classes.cardContainer}
      data-tut="reactour__reportLeft"
      style={{ paddingBottom: 0 }}
    >
      <CardContent className={classes.CardContent}>
        <MuiThemeProvider theme={theme}>
          <AppBar
            position="static"
            color="inherit"
            style={{ boxShadow: "none" }}
          >
            <Tabs
              value={fieldData.reportTab}
              onChange={handleTabChange}
              id="step_tab"
            >
              <Tab
                value={0}
                disableRipple
                className={classes.tab}
                label="Collect /Review data"
              />
              <Tab
                value={1}
                disableRipple
                className={classes.tab}
                label="Audit & Publish"
              />
              <Tab
                value={2}
                disableRipple
                className={classes.tab}
                label="Freeze data"
              />
            </Tabs>
          </AppBar>
          {/* {(fieldData.reportTab === 0 || fieldData.reportTab === 1) && (
            <Box m={2}>
              <Grid container>
                <Grid item xs={12} md={7}></Grid>
                <Grid item xs={12} md={5}></Grid>
              </Grid>
            </Box>
          )} */}

          {fieldData.reportTab === 0 || fieldData.reportTab === 1 ? (
            <div
              className="scroll"
              style={{ height: fieldData.reportTab === 0 ? "66vh" : "64vh" }}
            >
              <TabPanel value={fieldData.reportTab} index={0}>
                <Grid
                  item
                  md={12}
                  justifyContent="space-between"
                  container
                  style={{ padding: "4px 10px 5px 10px" }}
                >
                  <Grid md={6} item>
                    {/* <CustomTextfield
											variant="outlined"
											size="small"
											fullwidth
											label="search Metric"
											onChange={(e) => setSearchText(e.target.value)}
											value={searchText}
										/> */}
                    {/* <input
											onChange={(e) =>
												searchDeep(fieldData.topicMetric, e.target.value)
											}
										/> */}
                    {/*</Grid>
                  	 <Grid md={6} item>
                    {/* <CustomTextfield
                      name="chngToAll"
                      variant="outlined"
                      size="small"
                      select
                      fullwidth
                      style={{ width: '220px' }}
                      // value={metricList.suggestion}
                      onChange={(e) => {
                        handleFilterChange(e.target.value);
                      }}
                      all
                      selected
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton>
                            <AddBoxOutlinedIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    >
                      {['Relevance', 'Recommended'].map((item) => (
                        <MenuItem value={item} key={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </CustomTextfield> */}
                  </Grid>
                </Grid>
                <Grid container style={{ display: "flex" }}>
                  <Grid item md={8} lg={8} sm={8}>
                    <Card variant="outlined" className={classes.Card1}>
                      <CardContent className={classes.CardContent2}>
                        {basicInfo.frameworks.length > 0 &&
                          basicInfo.frameworks !== null &&
                          basicInfo.frameworks !== undefined &&
                          basicInfo.frameworks !== "" &&
                          basicInfo.frameworks.map(
                            (work, index) =>
                              work._id === basicInfo.frameworkId && (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  key={index}
                                >
                                  {work.image === undefined ? (
                                    ""
                                  ) : (
                                    <>
                                      {work.image === "" ||
                                      work.image === null ? (
                                        <Avatar className={classes.Avatar}>
                                          F
                                        </Avatar>
                                      ) : (
                                        <Avatar
                                          src={work.image[0]}
                                          className={classes.Avatar}
                                        />
                                      )}
                                    </>
                                  )}
                                  <Typography
                                    variant="subtitle2"
                                    gutterBottom
                                    className={classes.Typography2}
                                  >
                                    {work.framework_name}
                                  </Typography>
                                </Box>
                              )
                          )}
                        <IconButton
                          name="openFrmSelector"
                          className={classes.div1}
                          onClick={() => {
                            setFrameworkOpen(true);
                            // resetTemplateType('')
                          }}
                          // disabled={
                          //   (fieldData.topicMetric.length && !frameworkOpen) > 0
                          //     ? false
                          //     : true
                          // }
                        >
                          <ExpandMoreIcon />
                        </IconButton>
                      </CardContent>
                    </Card>
                    {FrameworksDialogs}
                  </Grid>
                  <Grid
                    item
                    md={3}
                    lg={3}
                    style={{ padding: "4px 0px 5px 0px" }}
                  >
                    <CustomTextfield
                      name="chngToAll"
                      variant="outlined"
                      size="small"
                      select
                      // disabled={fieldData.topicMetric.length === 0}
                      value={metricList.suggestion}
                      onChange={(e) => {
                        handleFilterChange(e.target.value);
                      }}
                    >
                      {["All Disclosures", "Recommended"].map((item) => (
                        <MenuItem value={item} key={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </CustomTextfield>
                  </Grid>
                </Grid>
                {fieldData.topicMetric.length > 0 ? (
                  fieldData.topicMetric.map(
                    (topic, index) =>
                      (topic.sub_topics.length === 0 ||
                        topic.sub_topics.length > 0) && (
                        <TopicCard
                          key={index}
                          topicMetric={topic}
                          mainTopicId={topic._id}
                        >
                          <DataTreeView
                            items={
                              topic?.sub_topics?.length > 0
                                ? topic?.sub_topics
                                : topic?.sub_metrics
                            }
                            mainTopicId={topic?._id}
                          />
                        </TopicCard>
                      )
                  )
                ) : (
                  <Card
                    variant="elevation"
                    elevation={0}
                    className={classes.CardLoader}
                  >
                    <CardContent className={classes.CardContentLoader}>
                      <NoData message={`No ${metricList?.suggestion || ""} Topic Found`} />
                      {/* <CircularProgress className={classes.loader} /> */}
                    </CardContent>
                  </Card>
                )}
              </TabPanel>

              {/* Audit & publish tab 1 */}

              <TabPanel value={fieldData.reportTab} index={1}>
                <div>
                  <AuditTab {...props} />
                </div>
              </TabPanel>
              {/* 
              <TabPanel value={fieldData.reportTab} index={1}>
                <div>
                  <Grid>
                    <Grid md={12} style={{ alignItems: 'flex-end' }}>
                      <Select>
                        <MenuItem>PDF</MenuItem>
                        <MenuItem>Excel</MenuItem>
                      </Select>
                      <Button>Share report</Button>
                    </Grid>
                    <Grid container>
                      <Grid
                        item
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          border: '1px solid lightGrey',
                          borderRadius: '8px',
                          width: '130px',
                          height: '110px',
                          // backgroundColor: '#EBF1F8',
                          marginTop: '20px',
                          marginLeft: '15px',
                        }}
                      >
                        <Grid style={{ width: '100%' }}>
                          {' '}
                          <Typography
                            style={{
                              // wordBreak: 'break',
                              color: '#3374B9',
                              fontSize: '14px',
                              textAlign: 'left',
                              padding: '8px 2px 8px 8px',
                              textAlign: 'center',
                            }}
                          >
                            Data Points Assigned
                          </Typography>
                        </Grid>
                        <Grid style={{ width: '100%', textAlign: 'center' }}>
                          <Typography
                            style={{
                              fontSize: '36px',
                              alignContent: 'bottom',
                              padding: '0px 12px',
                              color: '#3374B9',
                              fontWeight: 500,
                            }}
                          >
                            -
                            {/* {fieldData.metrics &&
                          fieldData.metrics.total_assigned_metrics === 0
                            ? '-'
                            : fieldData.metrics &&
                              fieldData.metrics.total_assigned_metrics} 
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          border: '1px solid lightGrey',
                          borderRadius: '8px',
                          width: '130px',
                          height: '110px',
                          // backgroundColor: '#EBF1F8',
                          marginTop: '20px',
                          marginLeft: '15px',
                        }}
                      >
                        <Grid style={{ width: '100%' }}>
                          {' '}
                          <Typography
                            style={{
                              // wordBreak: 'break',
                              color: '#3374B9',
                              fontSize: '14px',
                              textAlign: 'left',
                              padding: '8px 2px 8px 8px',
                              textAlign: 'center',
                            }}
                          >
                            Data Points Collected
                          </Typography>
                        </Grid>
                        <Grid style={{ width: '100%', textAlign: 'center' }}>
                          <Typography
                            style={{
                              fontSize: '36px',
                              alignContent: 'bottom',
                              padding: '0px 12px',
                              color: '#3374B9',
                              fontWeight: 500,
                            }}
                          >
                            -
                            {/* {fieldData.metrics &&
                          fieldData.metrics.total_assigned_metrics === 0
                            ? '-'
                            : fieldData.metrics &&
                              fieldData.metrics.total_assigned_metrics}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          border: '1px solid lightGrey',
                          borderRadius: '8px',
                          width: '130px',
                          height: '110px',
                          // backgroundColor: '#EBF1F8',
                          marginTop: '20px',
                          marginLeft: '15px',
                        }}
                      >
                        <Grid style={{ width: '100%' }}>
                          {' '}
                          <Typography
                            style={{
                              // wordBreak: 'break',
                              color: '#3374B9',
                              fontSize: '14px',
                              textAlign: 'left',
                              padding: '8px 2px 8px 8px',
                              textAlign: 'center',
                            }}
                          >
                            Data Points Completed
                          </Typography>
                        </Grid>
                        <Grid style={{ width: '100%', textAlign: 'center' }}>
                          <Typography
                            style={{
                              fontSize: '36px',
                              alignContent: 'bottom',
                              padding: '0px 12px',
                              color: '#3374B9',
                              fontWeight: 500,
                            }}
                          >
                            -
                            {/* {fieldData.metrics &&
                          fieldData.metrics.total_assigned_metrics === 0
                            ? '-'
                            : fieldData.metrics &&
                              fieldData.metrics.total_assigned_metrics} 
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Box className={classes.Box1}>
                    {fieldData.reviewData &&
                      fieldData.reviewData
                        .sort((a, b) =>
                          (
                            fieldData.topicMetric.length === 3
                              ? a.main_topic_name > b.main_topic_name
                              : a.main_topic_id > b.main_topic_id
                          )
                            ? 1
                            : (
                                fieldData.topicMetric.length === 3
                                  ? b.main_topic_name > a.main_topic_name
                                  : b.main_topic_id > a.main_topic_id
                              )
                            ? -1
                            : 0
                        )
                        .map((mainTopic) => {
                          return <MetricCard mainTopic={mainTopic} />;
                        })}
                  </Box>
                </div>
              </TabPanel> */}
            </div>
          ) : (
            <div className="scroll" style={{ height: "60vh" }}>
              <TabPanel value={fieldData.reportTab} index={2}>
                <Paper
                  elevation={0}
                  variant="outlined"
                  className={classes.freezeDataCard}
                >
                  <Box m={2}>
                    <Typography className={classes.freezeListText}>
                      The Freeze data tab is to ensure the data is secure and
                      stored in a blockchain.
                    </Typography>
                    <Typography className={classes.freezeListText}>
                      The link we develop is called Hash link which is SHA256
                      (256bits, 64 symbols) It is secure.
                    </Typography>
                    <Typography className={classes.freezeListText}>
                      SHA = Secure Hash Algorithm
                    </Typography>
                    <Typography className={classes.freezeListText}>
                      Even a smallest of change in the data will have a
                      completely unique code.{" "}
                    </Typography>
                    <Typography className={classes.freezeListText}>
                      The code in sha 256 is collision resistance which means
                      the same code wont be available for any other data.
                    </Typography>
                    <Typography className={classes.freezeListText}>
                      This option can be skipped if you do not wish to freeze
                      the data.
                    </Typography>
                  </Box>
                </Paper>
                <Box className={classes.freezeDataBtn}>
                  <CustomButton
                    disabled={fieldData.freezeLoading}
                    color="primary"
                    variant="contained"
                    onClick={() => freezeData(login.token, assessment_id)}
                  >
                    Freeze Data To Blockchain
                  </CustomButton>
                </Box>
              </TabPanel>
            </div>
          )}
        </MuiThemeProvider>
      </CardContent>
    </Card>
  );
};

export default ReportDataLeft;
