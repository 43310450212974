import React, { useMemo, useState } from "react";

import { CubeProvider } from "@cubejs-client/react";
import cubejs from "@cubejs-client/core";

// import { JsonWebTokenError } from "jsonwebtoken";
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import { Popover, makeStyles, MenuItem } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DataHubTabs from "../dataHub/dataHubTabs";
import Icon from "@material-ui/core/Icon";

import getChartData from "./chartDataforDashboard";

import filter from "../../../images/filter.png";
import ChartRenderer from "./ChartRenderedDashboard";
import DashboardItem from "../../UI/dataHubCalculatedCharts/component/DashboardItem";
import DashboardKpiDrawer from "../../UI/dataHubCalculatedCharts/component/dashboardKpiDrawer";
import DashboardPage from "../../UI/dataHubCalculatedCharts/pages/DashboardPage";
import ExploreQueryBuilder from "../../UI/dataHubCalculatedCharts/component/exploreQBuilder/ExploreQueryBuilder";
import CloseIcon from "@material-ui/icons/Close";
import Dashboard from "../../UI/dataHubCalculatedCharts/component/Dashboard";
import { useDispatch, useSelector } from "react-redux";
import {
  getDashboardCharts,
  setKpiDrawerState,
  setNewChartsLayout,
  setSelectedKpiType,
  updateDashboardChartsLayout,
} from "../../../actions/datahubActions/datahubActions";
import GeneralTab from "./GeneralTab";
import ChartsCompenents from "./ChartsCompenents";
import AddChartsDrawer from "./AddDrawer";

import CreateChartsDrawer from "./createCharts/CreateChartsDrawer";
import CustomButton from "../../UI/button/button";
import encrypt from "../../../actions/shared/sharedActions";
const TABS_ARR = [
  { value: 0, tab: "General", name: "general" },
  { value: 1, tab: "Environmental", name: "environmental" },
  { value: 2, tab: "Social Capital", name: "social_capital" },
  { value: 3, tab: "Governance", name: "governance" },
  { value: 4, tab: "Human Capital", name: "human_capital " },
  {
    value: 5,
    tab: "Business model & innovation",
    name: "business_model_and_innovation",
  },
];

const API_URL = "https://cube2.imgrows.app";

const security = { tab: "environmental" };

// console.log('security', security)

const CUBEJS_TOKEN =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NzExMDY1NDMsImV4cCI6MTY3MTE5Mjk0M30.-9_171MOu0Vs8rfl2f_siqdys9SYph6xfZDvFL81ilI";

const defaultLayout = (i) => ({
  x: i?.layout?.x || 0,
  y: i?.layout?.y || 0,
  w: i?.layout?.w || 4,
  h: i?.layout?.h || 8,
  minW: 4,
  minH: 8,
});

// styling for framework type dashboard
const useStyles = makeStyles((theme) => ({
  menuItem: {
    width: "210px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    color: "#000",
    background: "#FFFFFF",
    textTransform: "capitalize",
    height: "4em",
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
  },
}));

function Staticdashboard(props) {
  const dispatch = useDispatch();

  const { selectedTabChartsData, newLayout, selectedKpiType } = useSelector(
    (state) => state?.dataHub
  );

  const CURRENT_ORGANIZATION = localStorage.getItem("current_organisation");

  const CHARTDATA_TOPICWISE = getChartData(CURRENT_ORGANIZATION);

  const [selectedTab, setSelectedTab] = useState(0);

  // const [cubejsApi, setCubejsApi] = useState(null);

  const [tabName, setTabName] = useState("General");

  const [chartData, setChartData] = useState([]);
  const [selectedhartData, setSelectedhartData] = useState({});
  const [isStaticChartKpiDrawerOpen, setIsStaticChartKpiDrawerOpen] =
    React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isEdit, setIsEdit] = React.useState(false);
  const openEditMenu = Boolean(anchorEl);

  const TABS_TYPE_NEW = {
    Environmental: "environmental",
    "Social Capital": "socialCapital",
    Governance: "leadershipGovernance",
    "Human Capital": "humancapital",
    "Business Model & Innovation": "businessModel",
  };

  const cubejsApi = React.useMemo(
    () =>
      cubejs({
        apiUrl: `${API_URL}/cubejs-api/v1`,
        headers: {
          Authorization: ` ${selectedKpiType}/${TABS_TYPE_NEW[tabName]}` || "",
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedKpiType, tabName]
  );

  // React.useEffect(() => {
  //   setCubejsApi(
  //     cubejs({
  //       apiUrl: `${API_URL}/cubejs-api/v1`,
  //       headers: {
  //         Authorization: ` ${selectedKpiType}/${TABS_TYPE_NEW[tabName]}` || "",
  //       },
  //     })
  //   );
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedKpiType, tabName]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setIsEdit(true);
  };

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
    setTabName(event.target.innerText);
  };

  React.useEffect(() => {
    const handleKpiData = CHARTDATA_TOPICWISE[tabName]
      ? CHARTDATA_TOPICWISE[tabName]
      : [];

    setChartData(handleKpiData);
    setIsEdit(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabName]);
  const { charts } = props.dataHub;
  const { token, selectedOrganization, current_organisation } = props.login;

  const BACKGROUND_COLOR = [
    "rgba(255, 99, 132, 0.2)",
    "rgba(54, 162, 235, 0.2)",
    "rgba(255, 206, 86, 0.2)",
    "rgba(75, 192, 192, 0.2)",
    "rgba(153, 102, 255, 0.2)",
    "rgba(255, 159, 64, 0.2)",
    "rgba(210, 79, 31, 0.2)",
    "rgba(154, 6, 203, 0.22)",
  ];

  const BORDER_COLOR = [
    "rgba(255, 99, 132, 0.2)",
    "rgba(54, 162, 235, 0.2)",
    "rgba(255, 206, 86, 0.2)",
    "rgba(75, 192, 192, 0.2)",
    "rgba(153, 102, 255, 0.2)",
    "rgba(255, 159, 64, 0.2)",
    "rgba(210, 79, 31, 0.2)",
    "rgba(154, 6, 203, 0.22)",
  ];

  const [backgroundColor, setBackGroundColor] =
    React.useState(BACKGROUND_COLOR);

  const [borderColor, setBorderColor] = React.useState(BORDER_COLOR);

  const [chartTitle, setChartTitle] = React.useState("New Chart");
  const [chartDescription, setChartDescription] = React.useState(
    "New Chart Description"
  );
  const [axisLabels, setAxisLabels] = React.useState({
    x_labels: "",
    y_labels: "",
  });

  const [limit, setLimit] = React.useState(5000);

  React.useEffect(() => {
    document.body.style.overflow = "scroll";
    // props?.getDataHubChart(token, selectedOrganization._id);
    // dispatch(getDashboardCharts(token));
  }, []);

  // const dashboardItems = [
  //   {
  //     id: 0,
  //     name: "Total waste Recycled(in metric tonne) ",
  //     vizState: {
  //       query: {
  //         limit: 5000,
  //         measures: ["Recycling.Recyled_value"],
  //         order: {
  //           "Recycling.Recyled_value": "desc"
  //         },
  //         dimensions: ["Recycling.Recycled_quantity"],
  //         timeDimensions: []
  //       },
  //       chartType: "bar"
  //     },
  //     layout: {
  //       x: 0,
  //       y: 16,
  //       w: 6,
  //       h: 8,
  //       minW: 4,
  //       minH: 8
  //     }
  //   },
  //   {
  //     id: 1,
  //     name: "Percentage of Female Board of Directors",
  //     vizState: {
  //       query: {
  //         limit: 5000,
  //         measures: ["Percentage_Female_BOD.femaleBOD_value"],
  //         order: {
  //           "Percentage_Female_BOD.femaleBOD_value": "desc"
  //         },
  //         dimensions: ["Percentage_Female_BOD.percentageoffemaleBOD"],
  //         timeDimensions: []
  //       },
  //       chartType: "pie"
  //     },
  //     layout: {
  //       x: 0,
  //       y: 8,
  //       w: 4,
  //       h: 8,
  //       minW: 4,
  //       minH: 8
  //     }
  //   },
  //   {
  //     id: 2,
  //     name: "Waste generated",
  //     vizState: {
  //       query: {
  //         limit: 5000,
  //         measures: ["Total_Waste.total_waste_generated"],
  //         order: {
  //           "Total_Waste.total_waste_generated": "desc"
  //         },
  //         dimensions: ["Total_Waste.total_waste"],
  //         timeDimensions: []
  //       },
  //       chartType: "bar"
  //     },
  //     layout: {
  //       x: 0,
  //       y: 0,
  //       w: 12,
  //       h: 8,
  //       minW: 4,
  //       minH: 8
  //     }
  //   },
  //   {
  //     id: 3,
  //     name: "Total Energy Generated",
  //     vizState: {
  //       query: {
  //         limit: 5000,
  //         measures: ["Total_Energy.Total_Energy"],
  //         order: {
  //           "Total_Energy.Total_Energy": "desc"
  //         },
  //         dimensions: ["Total_Energy.Energy"],
  //         timeDimensions: []
  //       },
  //       chartType: "pie"
  //     },
  //     layout: {
  //       x: 8,
  //       y: 8,
  //       w: 4,
  //       h: 8,
  //       minW: 4,
  //       minH: 8
  //     }
  //   },
  //   {
  //     id: 4,
  //     name: "Emission Intensity",
  //     vizState: {
  //       query: {
  //         limit: 5000,
  //         measures: ["Total_Emission.Total_Emission"],
  //         order: {
  //           "Total_Emission.Total_Emission": "desc"
  //         },
  //         dimensions: ["Total_Emission.Emission"],
  //         timeDimensions: []
  //       },
  //       chartType: "bar"
  //     },
  //     layout: {
  //       x: 6,
  //       y: 16,
  //       w: 6,
  //       h: 8,
  //       minW: 4,
  //       minH: 8
  //     }
  //   },
  //   {
  //     id: 5,
  //     name: "Total Energy Consumed (in joules or multipls)",
  //     vizState: {
  //       query: {
  //         limit: 5000,
  //         measures: ["Total_Energy_Consumption.Total_Energy_Consumption"],
  //         order: {
  //           "Total_Energy_Consumption.Total_Energy_Consumption": "desc"
  //         },
  //         dimensions: ["Total_Energy_Consumption.Energy_Consumption"],
  //         timeDimensions: []
  //       },
  //       chartType: "pie"
  //     },
  //     layout: {
  //       x: 4,
  //       y: 8,
  //       w: 4,
  //       h: 8,
  //       minW: 4,
  //       minH: 8
  //     }
  //   },
  //   {
  //     id: 6,
  //     name: "Rehabilitation and Resettlement",
  //     vizState: {
  //       query: {
  //         limit: 5000,
  //         measures: ["Rehabilitated.Total_Rehabilitated"],
  //         order: {
  //           "Rehabilitated.Total_Rehabilitated": "desc"
  //         },
  //         dimensions: ["Rehabilitated.Rehabilitated"],
  //         timeDimensions: []
  //       },
  //       chartType: "pie"
  //     },
  //     layout: {
  //       x: 0,
  //       y: 24,
  //       w: 4,
  //       h: 8,
  //       minW: 4,
  //       minH: 8
  //     }
  //   },
  //   {
  //     id: 7,
  //     name: "Minimum Wages Paid",
  //     vizState: {
  //       query: {
  //         limit: 5000,
  //         measures: ["Average_Wages.Total_Wages"],
  //         order: {
  //           "Average_Wages.Total_Wages": "desc"
  //         },
  //         dimensions: ["Average_Wages.Wages"],
  //         timeDimensions: []
  //       },
  //       chartType: "bar"
  //     },
  //     layout: {
  //       x: 4,
  //       y: 24,
  //       w: 8,
  //       h: 8,
  //       minW: 4,
  //       minH: 8
  //     }
  //   },
  //   {
  //     id: 8,
  //     name: "Total Waste Genrated",
  //     vizState: {
  //       query: {
  //         limit: 5000,
  //         measures: [
  //           "Total_Waste_Generated_for_recycling.Total_Waste_Generated"
  //         ],
  //         order: {
  //           "Total_Waste_Generated_for_recycling.Total_Waste_Generated": "desc"
  //         },
  //         dimensions: ["Total_Waste_Generated_for_recycling.Waste_Generated"],
  //         timeDimensions: []
  //       },
  //       chartType: "bar"
  //     },
  //     layout: {
  //       x: 0,
  //       y: 32,
  //       w: 12,
  //       h: 8,
  //       minW: 4,
  //       minH: 8
  //     }
  //   }
  // ];

  // const [charts, setChart] = useState(charts ? charts : []);

  const [title, setTitle] = useState(null);

  const finalTitle = title != null ? title : "New charts";

  const [vizState, setVizState] = useState(null);

  const finalVizState = vizState || {};

  const [open, setOpen] = React.useState(false);

  const [openAddChartsDrawer, setOpenAddChartsDrawer] = React.useState(false);

  const [addChart, setAddChart] = React.useState(true);

  const [pivotConfig, setPivotConfiq] = React.useState({});

  const [barChartCustomize, setBarChartCustomize] = React.useState({
    borderWidth: 10,
    barThickness: 100,
    borderRadius: 20,
  });

  const [lineChartCustomize, setLineChartCustomize] = React.useState({
    pointRadius: 15,
    tension: 0.3,
    pointHoverRadius: 10,
    borderWidth: 6,
    pointStyle: "rectRounded",
  });

  const [pieChartCustomize, setPieChartCustomize] = React.useState({
    cutout: 0,
    rotation: 0,
    circumference: 360,
  });

  const [chartX_AxisCustomize, setChartX_AxisCustomize] = React.useState({
    display: true,
    text: "Your Title",
    align: "center",
    color: "red",
    font: {
      size: 15,
    },
  });

  const [chartY_AxisCustomize, setChartY_AxisCustomize] = React.useState({
    display: true,
    text: "Your Title",
    align: "center",
    color: "red",
    font: {
      size: 15,
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleDrawer = () => {
    // setSelectedTab(1);
    setOpen(true);
  };

  const TABS_TYPE = {
    Environmental: "environmental",
    "Social Capital": "social_capital",
    Governance: "governance",
    "Human Capital": "human_capital",
    "Business Model & Innovation": "business_model_and_innovation",
  };

  const handleAddChartsDrawer = () => {
    dispatch(
      getDashboardCharts(
        token,
        current_organisation,
        TABS_TYPE[tabName],
        false,
        selectedKpiType
      )
    );

    setOpenAddChartsDrawer(true);
  };

  const handleAddChart = () => {
    const selectedTabText = () => {
      if (selectedTab === 1) return "environmental";
      else if (selectedTab === 2) return "social_capital";
      else if (selectedTab === 3) return "governance";
      else if (selectedTab === 4) return "human_capital";
      else if (selectedTab === 5) return "business_model_and_innovation";
      return "";
    };
    const newChartObj = {
      // id: charts.length,
      name: chartTitle,
      axisLabels,
      description: chartDescription,
      type: selectedTabText(),
      chartType: vizState.chartType,
      topic: selectedTabText(),
      kpi: "",
      isCustom: true,
      vizState: {
        query: {
          limit,
          measures: vizState.query.measures,
          dimensions: vizState.query.dimensions,
          order: vizState.query.order,
          filters: vizState.query.filters,
          timeDimensions: vizState.query.timeDimensions,
        },
        chartType: vizState.chartType,
      },
      layout: {
        x: 0,
        y: 0,
        w: 4,
        h: 8,
        minW: 4,
        minH: 8,
        moved: false,
        static: false,
      },
      pivotConfig: vizState.pivotConfig,

      chartColors: {
        backgroundColors: backgroundColor,
        borderColors: borderColor,
        chartX_AxisCustomize,
        chartY_AxisCustomize,
      },
    };

    props.addDataHubChart(
      token,
      selectedOrganization._id,
      newChartObj,
      selectedKpiType
    );

    // getDashboardCharts
    // setChart((preCharts) => [...preCharts, newChartObj]);

    setOpen(false);
    setBackGroundColor(BACKGROUND_COLOR);
    setBorderColor(BORDER_COLOR);
    setLimit(5000);
    setChartTitle("New Chart");
    setChartDescription("New Chart Description");
    setAxisLabels({
      x_labels: "",
      y_labels: "",
    });
  };

  const handleCloseAddChartDrawer = () => {
    setOpenAddChartsDrawer(false);
  };

  // dropdown for framework type
  const classes = useStyles();
  // const { selectedKpiType } = useSelector((state) => state.dataHub);

  const [showMenu, setShowMenu] = useState(null);

  const handleFrameworkClick = (event) => {
    setShowMenu(event.currentTarget);
  };

  const handleFrameworkClose = () => {
    setShowMenu(null);
  };

  const CloseAlert = () => {
    <div componet={Paper}>
      <Typography>Create Chart</Typography>
      <div>
        <Typography>
          Closing this tab not store the changes. Are you sure you want to close
          this tab ?
        </Typography>
      </div>
      <div>
        <CustomButton color="primary" variant="outlined">
          Close
        </CustomButton>
        <CustomButton color="primary" variant="contained">
          Continue Editing
        </CustomButton>
      </div>
    </div>;
  };

  const handelSaveLayout = () => {
    dispatch(updateDashboardChartsLayout(token, newLayout));
    setIsEdit(false);
  };

  if (!cubejsApi) {
    return <span>Loading</span>;
  }

  return (
    <CubeProvider cubejsApi={cubejsApi}>
      <Box
        style={{
          padding: "10px 70px 10px 10px",
          backgroundColor: "#F3F4F4",
          margin: "-10px",
        }}
      >
        <Box style={{ display: "flex" }}>
          <Typography
            style={{
              fontSize: "32px",
              fontStyle: "normal",
              fontWeight: 500,
              color: "#15314E",
            }}
          >
            Dashboard
          </Typography>
          {selectedTab !== 0 &&
            (!isEdit ? (
              <div
                style={{
                  width: "90%",
                  marginLeft: "auto",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "10px",

                  // marginRight:"200px"
                }}
              >
                <CustomButton
                  color="primary"
                  variant="contained"
                  // onClick={handleClick}

                  onClick={handleDrawer}
                  //
                >
                  Create charts
                </CustomButton>

                {/* Dropdown for framework type */}
                <CustomButton
                  endIcon={<Icon component={"img"} src={filter} />}
                  variant="outlined"
                  style={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    background: "#FFFFFF",
                    borderRadius: "8px",
                    textTransform: "capitalize",
                    height: "3em",
                    color: "#15314E",
                    borderColor: "#C0D4E9",
                    width: "15%",
                    justifyContent: "space-between",
                  }}
                  onClick={handleFrameworkClick}
                >
                  {selectedKpiType}
                </CustomButton>

                {/* Popover for Selected Kpi Type */}
                <Popover
                  open={Boolean(showMenu)}
                  anchorEl={showMenu}
                  onClose={handleFrameworkClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => {
                      dispatch(setSelectedKpiType("BRSR"));
                      handleFrameworkClose();
                    }}
                  >
                    BRSR
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => {
                      dispatch(setSelectedKpiType("GRI"));
                      handleFrameworkClose();
                    }}
                  >
                    GRI
                  </MenuItem>
                </Popover>
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                  // style={{ border: "solid" }}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="long-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={openEditMenu}
                  onClose={handleCloseMenu}
                  getContentAnchorEl={null}
                  anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                  transformOrigin={{ vertical: "top", horizontal: "right" }}

                  // PaperProps={{
                  //   style: {
                  //     maxHeight: ITEM_HEIGHT * 4.5,
                  //     width: "20ch",
                  //   },
                  // }}
                >
                  <MenuItem
                    // key={option}
                    // selected={option === "Pyxis"}
                    onClick={handleCloseMenu}
                  >
                    Edit dashboard view
                  </MenuItem>
                </Menu>
              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  marginLeft: "auto",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "10px",
                  // marginRight:"200px"
                }}
              >
                <CustomButton
                  color="primary"
                  variant="contained"
                  // onClick={handleClick}

                  onClick={() => handelSaveLayout()}

                  //
                >
                  Save Changes
                </CustomButton>
                <Button
                  // color="primary"
                  variant="contained"
                  // onClick={handleClick}

                  onClick={() => setIsEdit(false)}
                  style={{ backgroundColor: "#FEFEFE", color: "#3374B9" }}
                  //
                >
                  Cancel
                </Button>
              </div>
            ))}
        </Box>
      </Box>
      <Box
        style={{
          padding: "10px",
          backgroundColor: "#F3F4F4",
          margin: "-10px",
        }}
      >
        <DataHubTabs
          value={selectedTab}
          tabs={TABS_ARR}
          handleTabs={handleChange}
        />
      </Box>
      <Box
        style={{
          backgroundColor: "#F3F4F4",
          height: "80vh",
          margin: "-10px",
          // border:"solid",
          overflow: "scroll",
          padding: "10px 70px 10px 10px",
        }}
      >
        {/* {selectedTab !== 0 && (
          <div
            style={{
              width: "90%",
              margin: "auto",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button color="primary" variant="contained" onClick={handleDrawer}>
              Add charts
            </Button>
          </div>
        )} */}

        {selectedTab !== 0 && (
          <CreateChartsDrawer
            onClose={handleClose}
            open={open}
            handleAddChart={handleAddChart}
            disabled={!addChart}
            builderProps={{
              cubejsApi: cubejsApi,
              vizState: finalVizState,
              setVizState: setVizState,
              backgroundColor: backgroundColor,
              borderColor: borderColor,
              setBackGroundColor: setBackGroundColor,
              setBorderColor: setBorderColor,
              setPivotConfiq: setPivotConfiq,
              setLimit: setLimit,
              barChartCustomize: barChartCustomize,
              lineChartCustomize: lineChartCustomize,
              chartX_AxisCustomize: chartX_AxisCustomize,
              chartY_AxisCustomize: chartY_AxisCustomize,
              pieChartCustomize: pieChartCustomize,
              setBarChartCustomize: setBarChartCustomize,
              setChartX_AxisCustomize: setChartX_AxisCustomize,
              setChartY_AxisCustomize: setChartY_AxisCustomize,
              setLineChartCustomize: setLineChartCustomize,
              setPieChartCustomize: setPieChartCustomize,
              setAddChart: setAddChart,
              chartTitle: chartTitle,
              setChartTitle: setChartTitle,
              setChartDescription: setChartDescription,
              chartDescription: chartDescription,
              initialBackgroundColor: BACKGROUND_COLOR,
              initialBorderColor: BORDER_COLOR,
              axisLabels:axisLabels,
              setAxisLabels:setAxisLabels
            }}
          />
        )}
        {/* {selectedTab !== 0 && (
          <Dialog
            fullWidth={true}
            maxWidth={"xl"}
            onClose={handleClose}
            open={open}
          >
            <DialogTitle>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography>Add charts</Typography>
                <TextField
                  value={chartTitle}
                  name="chartTitle"
                  onChange={(e) => setChartTitle(e.target.value)}
                ></TextField>
                <CloseIcon
                  style={{ cursor: "pointer" }}
                  onClick={handleClose}
                />
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleAddChart}
                  disabled={!addChart}
                >
                  Add charts
                </Button>
              </div>
            </DialogTitle>
            <ExploreQueryBuilder
              cubejsApi={cubejsApi}
              vizState={finalVizState}
              setVizState={setVizState}
              backgroundColor={backgroundColor}
              borderColor={borderColor}
              setBackGroundColor={setBackGroundColor}
              setBorderColor={setBorderColor}
              setPivotConfiq={setPivotConfiq}
              setLimit={setLimit}
              barChartCustomize={barChartCustomize}
              lineChartCustomize={lineChartCustomize}
              chartX_AxisCustomize={chartX_AxisCustomize}
              chartY_AxisCustomize={chartY_AxisCustomize}
              pieChartCustomize={pieChartCustomize}
              setBarChartCustomize={setBarChartCustomize}
              setChartX_AxisCustomize={setChartX_AxisCustomize}
              setChartY_AxisCustomize={setChartY_AxisCustomize}
              setLineChartCustomize={setLineChartCustomize}
              setPieChartCustomize={setPieChartCustomize}
              setAddChart={setAddChart}
              chartTitle={chartTitle}
              setChartTitle={setChartTitle}
            />
            <div style={{ width: "99%" }}>
              <Button
                style={{ margin: "10px 8px", float: "right" }}
                color="primary"
                variant="contained"
                onClick={handleAddChart}
                disabled={!addChart}
              >
                Add charts
              </Button>
            </div>
          </Dialog>
        )} */}

        {/* <div style={{ height: "100%" }}>
          {/* <DashboardPage
          // charts={charts}
          // dataStream_id={props?.dataStream_id}
          // dashboardCharts={charts}
          updateDashboardLayout={props.updateDashboardLayout}
          token={token}
          selectedOrganization={selectedOrganization._id}
          open={open}
          deleteDataHubChart={props?.deleteDataHubChart}
        /> 
        </div> */}
        {selectedTab !== 0 && selectedTabChartsData.length === 0 && !isEdit && (
          <div style={{ height: "90%", marginTop: "auto" }}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="column"
              style={{ height: "90%", marginTop: "auto" }}
            >
              <Grid
                item
                md={12}
                xs={12}
                sm={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // padding: "15px",
                  flexDirection: "column",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "18px",
                    lineHeight: "32px",
                    textAlign: "center",
                    textTransform: "capitalize",
                    color: "#242424",
                    padding: "1px 15px",
                  }}
                >
                  No Charts available
                </Typography>
              </Grid>
            </Grid>
          </div>
        )}

        {selectedTab !== 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            {isEdit && (
              <Button
                // color="primary"
                variant="contained"
                // onClick={handleClick}

                onClick={handleAddChartsDrawer}
                style={{
                  backgroundColor: "#FEFEFE",
                  color: "#3374B9",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                startIcon={<AddIcon />}
                //
              >
                Add Widgets
              </Button>
            )}

            <ChartsCompenents
              isEdit={isEdit}
              tabName={tabName}
              selectedTabChartsData={selectedTabChartsData}
              setSelectedhartData={setSelectedhartData}
              setIsStaticChartKpiDrawerOpen={setIsStaticChartKpiDrawerOpen}
            />

            {openAddChartsDrawer && (
              <AddChartsDrawer
                // inactiveCharts={inactiveCharts}
                openAddChartsDrawer={openAddChartsDrawer}
                handleCloseAddChartDrawer={handleCloseAddChartDrawer}
                tabName={TABS_TYPE[tabName]}
              />
            )}
          </div>
        )}

        {selectedTab !== 0 &&
          selectedTabChartsData.length > 0 &&
          isStaticChartKpiDrawerOpen && (
            <DashboardKpiDrawer
              title={selectedhartData?.name}
              chartData={selectedhartData}
              open={isStaticChartKpiDrawerOpen}
              onClose={() => setIsStaticChartKpiDrawerOpen(false)}
            />
          )}

        {selectedTab === 0 && <GeneralTab />}
      </Box>
    </CubeProvider>
  );
}

export default Staticdashboard;
